import { isNil } from 'ramda';
import sortByYear from '../sortByYear';

const getLatestYear = indicator => {
  const sortedByYear = sortByYear(Object.keys(indicator));

  const inner = years => {
    const currentYear = years[0];
    if (isNil(indicator[currentYear])) {
      return inner(years.filter(x => x !== currentYear));
    }

    return indicator[years[0]];
  };

  const latestYear = inner(sortedByYear);
  return latestYear;
};

export default getLatestYear;
