const sortByYear = years => {
  const shouldSplit = /^_\d{4}_\d{4}|^\d{4}\/\d{4}/g.test(years[0]);
  if (shouldSplit) {
    const useSlash = years[0].includes('/');
    const splitter = useSlash ? '/' : '_';
    const index = useSlash ? 0 : 1;

    return years.sort(
      (a, b) => b.split(splitter)[index] - a.split(splitter)[index]
    );
  }
  return years.sort((a, b) => b - a);
};

export default sortByYear;
