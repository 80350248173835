import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

const trackIndicatorInView = indicatorName => {
  trackCustomEvent({
    category: 'indicator',
    action: 'in-view',
    label: indicatorName,
  });
};

export default trackIndicatorInView;
